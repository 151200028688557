import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/motorrad/layout";
import SEO from "../../components/motorrad/seo";
import FlexCard from "../../components/motorrad/flexcard";
import DrivingSchoolCard from "../../components/drivingschoolcard";
import HomeVideoBackground from "../../components/homevideobackground";
import settings from "../../../settings";
import Popup from "reactjs-popup";

const bmwmotorradcard = `${settings.IMAGES_BASE_URL}/images/motorrad/home/homepage_rad.jpg`;
const bmwonroad = `${settings.IMAGES_BASE_URL}/images/motorrad/home/bmw-on-road-motorcycle-course.jpg`;
const bmwoffroad = `${settings.IMAGES_BASE_URL}/images/motorrad/home/bmw-off-road-motorcycle-course.jpg`;
const poster = `${settings.IMAGES_BASE_URL}/images/motorrad/home/poster-image-moto.jpg`;
const motoBanner = `${settings.IMAGES_BASE_URL}/images/motorrad/home/moto-qualify-banner.jpg`;
const womensOneDay = `${settings.IMAGES_BASE_URL}/images/motorrad/classes/womens-1-day.jpg`
const videoUrl = `${settings.IMAGES_BASE_URL}/videos/moto.mp4`;
class IndexPage extends React.Component {

  getDefaultCard = () => {
    return (
        <FlexCard
        rowType="row"
        image={motoBanner}
        imageAlt="GS Trophy"
        header="THE 2024 GS TROPHY IS COMING"
        subheader="COMPETE FOR A SEAT ON THE U.S. TEAM"
      >
        <p>
          Think you’re among the best of the best? You’ll be able to find out first hand on 
          October 14th as the GS Trophy Qualifier comes to the BMW Performance Center.
        </p>
        <Link to="/motorrad/gstrophy">
          <button className="btn btn-bordered">Learn More</button>
        </Link>
      </FlexCard>
    );
  };
  getFirstCard = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={womensOneDay}
          imageAlt="GIRL POWER"
          header="GIRL POWER"
          subheader="ALL-NEW WOMEN’S CLASSES AVAILABLE"
        >
          <p>
            Our new off-road courses are designed to make you feel comfortable on a BMW bike as you 
            explore new territory after the roads end.
          </p>
          <Link to="/motorrad/schools?desktop=4800&mobile=7500">
            <button className="btn btn-bordered">LEARN MORE</button>
          </Link>
        </FlexCard>
      </>
    );
  };

  getGoProCard =  () => {
    return (
      <FlexCard
          rowType="row-reverse"
          image={bmwmotorradcard}
          imageAlt="BMW Mottorrad Reward"
          header="Rewards At Full Throttle"
          subheader="Earn Rewards On Everyday Purchases"
      >
        <p>
          Designed for riders, the BMW Motorrad Card earns points toward
          rewards and includes exceptional complimentary benefits. With up
          to 4X points per $1 spent on eligible BMW Motorrad purchases,
          earning has never been so easy. It's time to explore your benefits
          - this is your road to valuable rewards.
        </p>
        <Link to="/motorrad/reward?utm_source=MotorradHomePage&utm_medium=banner3&utm_campaign=MotorradCard">
          <button className="btn btn-bordered">Learn More</button>
        </Link>
      </FlexCard>
    )
  };
  render() {
    return (
      <Layout>
        <SEO
          title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
          description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
        />
        <HomeVideoBackground
          headerTitle="Wheels Down, Throttle out, let’s ride"
          url={videoUrl}
          poster={poster}
          motorradvolume="true"
        />
        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h1 className="motorrad__title">THE BMW U.S. RIDER ACADEMY</h1>
            </div>
          </div>
          {this.getFirstCard()}
          {this.getGoProCard()}
          
        </section>

        <section className="testimonial">
          <div className="testimonial__content">
            <h3>
              “I’ve ridden for years and was amazed at the amount of info I
              didn’t have.”
            </h3>
            <span> - Jeff H.</span>
          </div>
        </section>

        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2>BMW RIDING SCHOOLS</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={bmwonroad}
            imageAlt="image of Fast Car"
            header="ON-ROAD"
            subheaderMini="LET THE MOMENTUM GRAB YOU"
            text="Build a momentum of technique, control and comfort in the saddle with every new revolution of the wheel. We’ll cover controlled clutch and throttle movements, low-speed maneuverability, overall balance and more. Every scenario is built to enhance your real-world enjoyment and safety when riding. Rev it up."
            learnUrl="/motorrad/schools?utm_source=MotorradHomePage&utm_medium=banner4&utm_campaign=OnRoadGrab"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-bordered"
            callBtn="true"
          />
          <DrivingSchoolCard
            rowType="row"
            image={bmwoffroad}
            imageAlt="image of Driver School Car"
            header="OFF-ROAD"
            subheaderMini="OPEN THE DOOR TO NEW ADVENTURES"
            text="Only five percent of the world’s roads are paved, which means one thing: You’re going to get dirty. Off-road classes traverse challenging terrain that leaves students with increased confidence and newfound riding ability."
            learnUrl="/motorrad/schools?utm_source=MotorradHomePage&utm_medium=banner5&utm_campaign=OffRoadDoor"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-bordered"
            callBtn="true"
          />
        </section>
      </Layout>
    );
  }
}
export default IndexPage;
